
.status_title {
  color: white;
  margin: 0;
  padding: 2.5em 0 0 0;
  font-size: 1.25rem;
  line-height: 1.5;
  text-transform: uppercase;
}

.status_subtitle {
  color: white;
  margin: 0;
  padding: 0 0 2.5em 0;
  font-size: 0.75rem;
  line-height: 1.5;
  text-transform: uppercase;
}

.status_main {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  background: url("../../../../img/Background_modal.png") no-repeat;
  background-size: cover;
  width: 300px;
  height: 235px;
}

.status_button {
  font-family: "Press Start 2P";
  color: #ffffff;
  cursor: pointer;
  min-width: 100%;
  display: flex;
  justify-content: center;
  line-height: 1;
  background: linear-gradient(360deg, #2da4f6 0%, #2451b6 100%);
  border: 3px solid #ffffff;
  padding: 1rem 1.25rem;
  text-decoration: none;
}

.successful {
  background: linear-gradient(360deg, #7cde7b 0%, #14ab12 100%);
}

.unsuccessful {
  background: linear-gradient(360deg, #ffa4a4 0%, #ff4242 100%);
}

.status_wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  color: white;
  font-size: 0.75rem;
}

.status_block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 2px solid white;
  background: #2daafa;
  padding: 0.625rem;
}

.ref_hash_content_done_mobile{
  font-size: 10px;
  color: #FFFFFF;
  cursor: pointer;
}

.wrap_refs{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.ref_opensea{
  font-size: 10px;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: underline;
}

.text_opensea{
  font-size: 10px;
  color: #FFFFFF;
  cursor: pointer;
}