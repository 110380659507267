.button_confirm_enabled {
  background: linear-gradient(180deg, #2da4f6 0%, #2451b6 100%);
  border: 2px solid #091b4b;
  padding: 10px;
  max-width: 420px;
  width: 100%;
  cursor: pointer;
}

.button_confirm_enabled:hover {
  background: #355c77;
}

.button_confirm_enabled span {
  font-family: "Press Start 2P";
  color: #ffffff;
  font-size: 20px;
}

.button_confirm_disabled {
  padding: 10px;
  max-width: 420px;
  width: 100%;
  border: 2px solid #091b4b;
  background: linear-gradient(360deg, #759db9 0%, #2e3442 100%);
}

.button_confirm_disabled span {
  font-family: "Press Start 2P";
  color: #ffffff;
  font-size: 20px;
}

@media (max-width: 800px) {
  .button_confirm_enabled {
    max-width: 323px;
    padding: 5px;
  }

  .button_confirm_disabled {
    max-width: 323px;
    padding: 5px;
  }

  .button_confirm_enabled span {
    font-size: 16px;
  }

  .button_confirm_disabled span {
    font-size: 16px;
  }
}

@media (max-width: 435px) {
  .button_confirm_enabled {
    max-width: 243px;
  }

  .button_confirm_disabled {
    max-width: 243px;
  }

  .button_confirm_enabled span {
    font-size: 12px;
  }

  .button_confirm_disabled span {
    font-size: 12px;
  }
}
