.container {
  width: 90%;
}

.homepage_container {
  min-height: 100%;
}

.homepage_title {
  color: white;
  margin: 0;
  padding: 2.5em 0;
  font-size: 1.25rem;
  line-height: 1.5;
  text-transform: uppercase;
  width: 13ch;
}

.homepage_main {
  border: 3px dotted white;
  border-radius: 50%;
}

.homepage_main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 10rem;
  margin-bottom: 5rem;
}

.homepage_main > div:nth-child(2) > svg {
  transform: scale(-1, 1);
  margin-right: 3rem;
  float: right;
}

.homepage_main > div:nth-child(3) {
  margin-left: 2rem;
}

.homepage_main > div:nth-child(4) > div {
  float: right;
}

.homepage_button {
  min-width: 100%;
  display: flex;
  align-items: center;
}

.homepage_wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  color: white;
  font-size: 0.75rem;
}

.homepage_block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 2px solid white;
  background: #2daafa;
  padding: 0.625rem;
}

.footer {
  padding-block: 4.5rem 1rem;
  text-align: center;
}
