.wrap_main_page{
    background: url("../../../img/Website_backgroud.png") no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrap_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



