::-webkit-scrollbar {
  width: 8px;
  color: #375bbe;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #375bbe;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #375bbe;
}