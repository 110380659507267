.wrap_button_disc{
    cursor: pointer;
    width: 100%;
    padding: 10px;
    background: linear-gradient(360deg, #2DA4F6 0%, #2451B6 100%);
    border: 3px solid #FFFFFF;
    text-align: center;
}

.wrap_button_disc:hover{
    background: #355C77;
}

.wrap_button_disc span {
    font-family: "Press Start 2P";
    font-weight: 400;
    font-size: 24px;
    text-decoration: none;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}
