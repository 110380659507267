.wrap_modal_status{
    position: relative;
    padding: 40px 75px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url("../../../../img/Background_modal.png") no-repeat;
    background-size: cover;
    gap: 30px;
}

.wrap_title_status{
    text-align: center;
    max-width: 420px;
}

.wrap_title_status span{
    color: #FFFFFF;
    font-size: 20px;
    text-transform: uppercase;
}

.wrap_chains_status{
    border-top: 3px dotted white;
    border-bottom: 3px dotted white;
    border-radius: 50%;
    width: 100%;
    padding: 55px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrap_chain_status{
    box-sizing: border-box;
    width: 183px;
    border: 2px solid #091B4B;
    padding: 30px 20px 15px 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
}

.chain_status{
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;
    width: 86px;
    padding: 10px;
    background: url("../../../../img/background_chain_status.png") no-repeat;
    background-size: cover;
}

.chain_status img{
    width: 100%;
    height: 100%;
}

.choosed_chains{
    align-items: center;
    display: flex;
    gap: 5px;
}

.choosed_chains img{
    width: 24px;
    height: 24px;
}

.choosed_chains span{
    font-size: 14px;
    color: #091B4B
}

.ref_hash{
    position: absolute;
    font-size: 14px;
    color: #FFFFFF;
    bottom: 65px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ref_hash_next{
    position: absolute;
    font-size: 14px;
    color: #FFFFFF;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
}
