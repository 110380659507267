.wrap_dropDawn {
  max-width: 175px;
  width: 100%;
  position: relative;
}

.wrap_values_drop_down {
  display: flex;
  gap: 5px;
}

.dropDawn_transfer {
  box-sizing: border-box;
  background: #ffffff;
  border: 3px solid #091b4b;
  width: 100%;
  gap: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.text_title {
  color: #091b4b;
  font-size: 12px;
}

.wrap_logos {
  display: flex;
  gap: 5px;
  align-items: center;
}

.wrap_logos img {
  width: 24px;
  height: 24px;
}

.wrap_chains_values {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  border: 3px solid #091b4b;
  left: 0;
  background: white;
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 75px;
  z-index: 9;
}

.wrap_chains_values img {
  width: 24px;
  height: 24px;
}

.wrap_chains {
  display: flex;
  gap: 5px;
  align-items: center;
  background: #ffffff;
  cursor: pointer;
  width: 100%;
}

.default_text {
  color: #091b4b;
  font-size: 12px;
}

.chains_span {
  line-height: 20px;
  color: #091b4b;
  font-size: 12px;
}

.wrap_chains:hover {
  background: #2da4f6;
}

.wrap_chains:hover span {
  color: #ffffff;
}

@media (max-width: 600px) {
  .dropDawn_transfer {
    width: 122px;
    gap: 5px;
    padding: 5px;
  }

  .wrap_chains_values {
    width: 122px;
  }

  .text_title {
    font-size: 8px;
  }

  .default_text {
    color: #091b4b;
    font-size: 8px;
  }

  .chains_span {
    color: #091b4b;
    font-size: 8px;
  }
  .wrap_values_drop_down {
    gap: 0;
  }

  .wrap_logos img {
    width: 18px !important;
    height: 18px !important;
  }
}
