.wrap_inventory {
  position: relative;
  background: url("../../../img/background_inventory.png") no-repeat;
  background-size: cover;
  margin-top: 70px;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 535px;
  width: 580px;
}


.wrap_nfts_inventory {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: auto;
}

.close_button_inventory {
  color: #ffffff;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

@media (max-width: 800px) {
  .close_button_inventory {
    top: 5px;
    right: 5px;
  }
}

