.button_status_progress {
  width: 100%;
  max-width: 590px;
  padding: 10px;
  border: 3px solid #fff;
  background: linear-gradient(360deg, #4d7c9c 0%, #2451b6 100%);
}

.button_status_progress span {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: "Press Start 2P";
  text-transform: uppercase;
}

.button_status_done {
  cursor: pointer;
  width: 100%;
  max-width: 590px;
  padding: 10px;
  border: 3px solid #fff;
  background: linear-gradient(360deg, #7cde7b 0%, #14ab12 100%);
}


.button_status_done span {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: "Press Start 2P";
  text-transform: uppercase;
}

.button_status_failed{
  cursor: pointer;
  width: 100%;
  max-width: 590px;
  padding: 10px;
  border: 3px solid #FFF;
  background: linear-gradient(360deg, #FFA4A4 0%, #FF4242 100%);
}

.button_status_failed span {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: "Press Start 2P";
  text-transform: uppercase;
}


@media (max-width: 600px) {
  .button_status_progress {
    max-width: 300px;
    padding: 7px;
    padding-bottom: 4px;
  }

  .button_status_progress span {
    font-size: 12px;
  }

  .button_status_done {
    max-width: 300px;
    padding: 7px;
    padding-bottom: 4px;
  }

  .button_status_done span {
    font-size: 12px;
  }
}
