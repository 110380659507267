.wrap_header {
  display: flex;
  background: #05359a;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  gap: 20px;
}

.left_side {
  display: flex;
  align-items: center;
  gap: 15px;
}

.wrap_beta_text_mobile{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #FFF;
  text-align: center;
  padding: 10px;
}

.wrap_beta_text_mobile span{
  color: #FFFFFF;
  font-size: 10px;
}

.wrap_beta_text{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #FFF;
  padding: 14px;
  max-width: 640px;
  width: 100%;
  text-align: center;
}

.wrap_beta_text span{
  color: #FFFFFF;
  font-size: 15px;
}

.left_side img {
  max-width: 80px;
}

.navigate_span {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
}
.find_nft{
  display: none;
}

.connect_button {
  line-height: 25px;
  font-weight: 400;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer;
}

.account_button {
  font-family: "Press Start 2P";
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  column-gap: 10px;
  background-color: #081538;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  border: 0;
}

.account_button_balance {
  padding: 10px 0;
}

.account_button_address {
  padding: 10px;
  background-color: #38426b;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .wrap_header {
    z-index: 1;
    position: relative;
    align-items: center;
    padding: 0.625rem 1.125rem;
    background: #2453b7;
  }

  .menu_shadow {
    box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.5);
  }

  .mobile_menu {
    z-index: 1;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .mobile_menu img {
    max-width: 2.75rem;
  }

  .wrap_header .connect_button {
    min-width: auto;
    font-size: 0.75rem;
    line-height: 1;
    background: linear-gradient(360deg, #2da4f6 0%, #2451b6 100%);
    border: 1px solid #ffffff;
    padding: 0.3rem 1.25rem;
    text-decoration: none;
  }

  .account_button {
    border-radius: 5px;
    font-size: 0.5rem;
  }

  .account_button_balance {
    padding: 0.25rem 0;
  }

  .account_button_address {
    padding: 0.25rem;
    border-radius: 5px;
  }

  .connect_button {
    width: 90%;
    text-align: center;
    line-height: 1.5;
    padding-block: min(1.25rem, 3vw);
    background: linear-gradient(360deg, #2da4f6 0%, #2451b6 100%);
    border: 3px solid #ffffff;
    text-decoration: none;
  }

  .mobile_menu_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.625rem;
  }

  .mobile_menu_button {
    padding: 0;
    border: none;
    background: transparent;
  }

  .mobile_menu_links {
    z-index: 1;
    list-style: none;
    position: absolute;
    right: 0;
    top: 1.75rem;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 1.25rem;
    padding: 1rem 1.125rem;
    background-color: #2453b7;
  }

  .mobile_menu_links span{
    font-size: 12px;
  }
}

@media (max-width: 1400px) {
  .find_nft{
    display: block;
  }
}