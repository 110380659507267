.wrap_nft_inventory {
  cursor: pointer;
  background: #375bbe;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.wrap_nft_inventory img {
  max-width: 125px;
  width: 100%;
  border: 4px solid #ffffff;
}

.wrap_nft_inventory:hover {
  background: #091b4b;
}

.wrap_nft_value_inventory {
  display: flex;
  flex-direction: column;
}

.wrap_nft_value_inventory span {
  margin-right: auto;
  font-size: clamp(10px, 1vw + 0.5rem, 16px);
  color: white;
}
