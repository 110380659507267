
.wrap{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(200, 214, 255, 0.20);
    backdrop-filter: blur(2.5px);
}

.wrap_modal_retry{
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("../../../../../img/backbround_modal_retry.png") no-repeat;
    background-size: cover;
    width: 594px;
    box-sizing: border-box;
    height: 329px;
    display: flex;
    padding: 50px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.close_button_retry{
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000000;
    cursor: pointer;
}

.text_title_retry{
    line-height: normal;
    font-size: 16px;
}

.wrap_dropDawn_retry{
    width: 200px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    border: 2px solid #091B4B;
    padding: 5px 10px;
}

.wrap_dropDawn_retry img{
    width: 22px;
    height: 22px;
}

.wrap_all_chains{
    border: 2px solid #091b4b;
    box-sizing: border-box;
    padding: 5px;
    background: white;
    bottom: -105px;
    left: -2px;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200px;
}

.wrap_all_chains img{
    width: 22px;
    height: 22px;
}

.button_retry{
    padding: 10px 0;
    width: 350px;
    cursor: pointer;
    border: 3px solid #091B4B;
    background: linear-gradient(360deg, #4D7C9C 0%, #2451B6 100%);
}

.button_retry span{
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: Press Start 2P;
    font-size: 24px;
}

@media (max-width: 600px) {
    .wrap_modal_retry{
        padding: 30px;
        width: 490px;
        height: 272px;
    }

    .button_retry{
        width: 260px;
    }
    .button_retry span{
        font-size: 20px;
    }

    .text_title_retry{
        font-size: 13px;
    }

    .close_button_retry{
        right: 2px;
    }
}

@media (max-width: 500px) {
    .wrap_dropDawn_retry span{
        font-size: 12px;
    }

    .wrap_dropDawn_retry{
        width: 170px;
    }

    .wrap_all_chains{
        width: 170px;
    }
    .wrap_modal_retry{
        padding: 15px;
        width: 320px;
        height: 177px;
    }

    .button_retry{
        padding: 5px;
        width: 170px;
    }
    .button_retry span{
        font-size: 12px;
    }

    .text_title_retry{
        font-size: 9px;
    }

    .close_button_retry{
        right: 2px;
        top: 2px;
        font-size: 10px;
    }
}