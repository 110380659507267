.wrap_nft {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background: #ffffff;
  border: 3px solid #091b4b;
}

.choosed_chain {
  font-size: 13px;
}

.wrap_values_nft {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text_choose {
  max-width: 144px;
  font-size: 14px;
}

.wrap_nft > img {
  width: 100%;
  max-width: 144px;
  cursor: pointer;
}

.chain_img {
  width: 24px;
  height: 24px;
}

.wrap_chain_connect {
  max-width: 144px;
  display: flex;
  gap: 5px;
  align-items: center;
}

@media (max-width: 600px) {
  .wrap_nft {
    width: 105px;
    padding: 5px;
  }

  .wrap_nft:has(.text_choose) {
    margin-top: 15px;
  }

  .text_choose {
    padding-left: 7px;
    font-size: 8px;
  }

  .wrap_nft > img {
    margin: 0 auto;
    width: 100%;
    max-width: 92px;
  }

  .chain_img {
    width: 18px;
  }

  .choosed_chain {
    font-size: 8px;
  }

  .wrap_values_nft {
    gap: 3px;
    font-size: 8px;
  }
}
