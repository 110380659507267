.wrap_modal_status_done{
    padding: 40px 95px;
    width: 590px;
    height: 460px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url("../../../../img/Background_modal.png") no-repeat;
    background-size: cover;
    gap: 10px;
}

.wrap_title_status_done{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 420px;
    gap: 10px
}

.text_under_title_status{
    color: #FFFFFF;
    font-size: 14px;
}

.title_status{
    color: #FFFFFF;
    font-size: 20px;
    text-transform: uppercase;
}

.wrap_chains_status_done{
    border: 3px dotted white;
    border-radius: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.wrap_nft_content{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.nft_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: white;
    padding: 12px;
}

.img_nft{
    max-width: 140px;
    width: 100%;
}

.wrap_status_values{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.ref_hash_content_done{
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
}
.ref_opensea{
    text-decoration: underline;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
}

.text_opensea{
    font-size: 14px;
    color: #FFFFFF;
}