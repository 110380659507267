.wrap_footer {
  padding: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.wrap_social{
  display: flex;
  gap: 30px;
}

.wrap_social a{
  cursor: pointer;
}

.wrap_social a img{
  max-width: 30px;
}

.text_footer {
  text-transform: uppercase;
  color: white;
  font-size: 15px;
}

@media (max-width: 600px) {
  .text_footer {
    font-size: 14px;
  }

}
