.container {
  min-height: 100%;
  background: url("../../../../img/Website_backgroud.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-inline: 1.125rem;
}
.accepted_title {
  color: white;
  margin: 0;
  padding: 2.5em 0;
  font-size: 1.25rem;
  line-height: 1.5;
  text-transform: uppercase;
  width: 13ch;
}

.accepted_main {
  border: 3px dotted white;
  border-radius: 50%;
}

.accepted_main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 10rem;
  margin-bottom: 5rem;
}

.accepted_main > div:nth-child(2) > svg {
  transform: scale(-1, 1);
  margin-right: 3rem;
  float: right;
}

.accepted_main > div:nth-child(3) {
  margin-left: 2rem;
}

.accepted_main > div:nth-child(4) > div {
  float: right;
}

.accepted_button {
  font-family: "Press Start 2P";
  color: #ffffff;
  cursor: pointer;
  min-width: 100%;
  display: flex;
  justify-content: center;
  line-height: 1;
  background: linear-gradient(360deg, #2da4f6 0%, #2451b6 100%);
  border: 3px solid #ffffff;
  padding: 1rem 1.25rem;
  text-decoration: none;
}

.accepted_wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  color: white;
  font-size: 0.75rem;
}

.accepted_block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 2px solid white;
  background: #2daafa;
  padding: 0.625rem;
}

.footer {
  padding-block: 4.5rem 1rem;
  text-align: center;
}

.ref_hash{
  font-size: 14px;
  color: #FFFFFF;
}
