.wrap_transfer_info {
  background: url("../../../img/background_inventory.png") no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;
  width: 600px;
  height: 555px;
}

.wrap_transfer_info_block {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.title_transfer_info {
  font-size: 20px;
  color: #091b4b;
  text-transform: uppercase;
}

.wrap_nft_value {
  max-width: 420px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  background: #375bbe;
  border: 2px solid #091b4b;
}

.wrap_nft_value img {
  max-width: 60px;
}

.wrap_nft_value span {
  font-size: 14px;
  color: #ffffff;
}

.text_process {
  max-width: 420px;
  text-align: center;
}

.text_process span {
  font-size: 16px;
  color: #091b4b;
}

.active_wallet {
  max-width: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  background: #375bbe;
  border: 2px solid #091b4b;
}

.text_active {
  font-size: 16px;
  color: #ffffff;
}

.text_address {
  font-size: 13px;
  color: #ffffff;
}

.wrap_powered_by {
  display: flex;
  gap: 5px;
  align-items: center;
}

.powered_by {
  text-transform: uppercase;
  font-size: 9px;
}

.close_button{
  color: #ffffff;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

@media (max-width: 800px) {
  .wrap_transfer_info {
    width: 400px;
    height: 355px;
    background-size: contain;
    gap: 10px;
  }

  .close_button{
      font-size: 12px;
    right: 19px;
  }

  .title_transfer_info {
    font-size: 16px;
  }

  .wrap_nft_value {
    max-width: 300px;
    gap: 15px;
    padding: 5px 10px;
  }

  .wrap_nft_value img {
    max-width: 40px;
  }

  .text_process span {
    font-size: 10px;
  }

  .active_wallet {
    max-width: 300px;
    gap: 10px;
  }

  .text_active {
    font-size: 10px;
  }

  .text_address {
    font-size: 8px;
  }

  .text_fee {
    font-size: 8px;
  }
}

@media (max-width: 435px) {
  .wrap_transfer_info {
    width: 320px;
    height: 295px;
    background-size: contain;
    gap: 5px;
  }

  .close_button{
    font-size: 10px;
    right: 4px;
  }

  .title_transfer_info {
    font-size: 14px;
  }

  .wrap_nft_value {
    max-width: 220px;
    gap: 15px;
  }

  .wrap_nft_value span{
    font-size: 12px;
  }

  .wrap_nft_value img {
    max-width: 30px;
  }

  .text_process span {
    font-size: 8px;
  }

  .active_wallet {
    max-width: 220px;
    gap: 10px;
  }

  .text_active {
    font-size: 8px;
  }

  .text_address {
    font-size: 6px;
  }

  .text_fee {
    font-size: 6px;
  }
}
