.container {
  width: 90%;
  margin: 0 auto;
}

.choose_title {
  color: white;
  margin: 0;
  padding-block: 2.5em 1em;
  font-size: clamp(0.75rem, 2vw + 0.5rem, 1.25rem);
  line-height: 1.5;
  text-transform: uppercase;
  width: 13ch;
}

.choose_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.75rem;
  padding-bottom: 1.25rem;
}

.choose_button_wrapper {
  min-width: 100%;
  display: flex;
  justify-content: center;
}

.choose_button {
  min-width: 100%;
  font-family: inherit;
  color: white;
  line-height: 1.5;
  padding-block: min(1.25rem, 3vw);
  background: linear-gradient(360deg, #2da4f6 0%, #2451b6 100%);
  border: 3px solid #ffffff;
  text-decoration: none;
  margin-top: 1.25rem;
}

.nft_wrapper_nfts{
  display: flex;
  justify-content: center;
}

.nft_wrapper {
  max-height: 21.25rem;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 143px);
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
}


.nft_card_wrapper {
  color: white;
  background: #2daafa;
  padding: 0.25rem;
  height: fit-content;
}

.nft_card_wrapper_transaction {
  color: #091b4b;
  background: white;
  border: 2px solid #091b4b;
  padding: 0.25rem;
  height: fit-content;
}

.nft_card_wrapper_status {
  text-align: start;
  min-width: 30%;
  color: #091b4b;
  background: white;
  border: 2px solid #091b4b;
  padding: 0.25rem;
  height: fit-content;
}

.active {
  background: #091b4b;
}

.nft_card_img {
  margin: 0;
  min-width: 100%;
  padding-bottom: 0.625rem;
}

.nft_card_inner_wrapper {
  display: flex;
  flex-direction: column;
}

.nft_card_title {
  font-size: inherit;
  font-size: 0.75rem;
}

.nft_card_number {
  font-size: 0.5rem;
}

.wrap_nonft{
  width: 100%;
  text-align: center;
}

.text_nonft{
  color: white;
}
